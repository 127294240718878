import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { FaturaConciliacaoReenvio } from '../models/faturaConciliacaoReenvio.model';
import { FaturaConciliacaoUploadReenvioFiltro } from '../models/Filters/faturaConciliacaoUploadReenvioFiltro.model';
import { FaturaConciliacaoFiltro } from '../models/Filters/faturaConciliacaoFiltro.model';


@Injectable()
export class FaturaConciliacaoService extends BaseService {
	constructor(){
		super('/api/faturaConciliacao/')
	}

    public GetAllFaturaConciliacaoIntegracao(fatura : FaturaConciliacaoFiltro): Observable<any> {
		return this._http.post(`${this._config.baseUrl}${this._api}GetAllFaturaConciliacaoIntegracao`, fatura);
	}
	public GetJsonIntegracaoFaturaConciliacao(empresaIntegracaoItemDetalheId : number): Observable<any> {
		return this._http.get(`${this._config.baseUrl}${this._api}GetJsonIntegracaoFaturaConciliacao/${empresaIntegracaoItemDetalheId}`);
	}
	public ReenviarFaturaConciliacaoIndividual(conciliacaoReenvio : FaturaConciliacaoReenvio): Observable<any> {
		return this._http.post(`${this._config.baseUrl}${this._api}ReenviarFaturaConciliacaoIndividual`, conciliacaoReenvio);
	}
	public ReenviarFaturaConciliacaoMassivo(lstConciliacaoReenvio : Array<FaturaConciliacaoReenvio>): Observable<any> {
		return this._http.post(`${this._config.baseUrl}${this._api}ReenviarFaturaConciliacaoMassivo`, lstConciliacaoReenvio);
	}
	public GetFilaReprocessamento(faturaId : number): Observable<any> {
		return this._http.get(`${this._config.baseUrl}${this._api}GetFilaReprocessamento/${faturaId}`);
	}
	UploadFaturaConciliacaoMassivo(form: FormData) {
		return this._http.post(`${this._config.baseUrl}${this._api}upload`, form);
	}
	public DownloadFaturaConciliacao(faturaId : number): Observable<any> {
		return this._http.post(`${this._config.baseUrl}${this._api}Download/${faturaId}`, null, { responseType: 'blob' });
	}
	public DownloadFaturaConciliacaoIntegracaoLayout(listaIntegracoes : Array<any>): Observable<any> {
		return this._http.post(`${this._config.baseUrl}${this._api}DownloadLayout`, listaIntegracoes, { responseType: 'blob' });
	}
	public DownloadDoccobConciliacao(faturaId : number): Observable<any> {
		return this._http.post(`${this._config.baseUrl}${this._api}DownloadDoccob/${faturaId}`, null, { responseType: 'blob' });
	}
	
}
